import $ from "jquery"

/**
 * Search category selection removal of nbsp and adding class for sub categories
 */
$(window).on('load', function(){
    $('.fancy-select-results ul li').each(function(){
        const filterListText = $(this).html();
        const filterListTextMatch = filterListText.match(/^(\&nbsp;)*/)[0].match(/&nbsp;/gm);

        if(filterListTextMatch){
            $(this).addClass('fancyselectresults-indent');
        }

        $(this).html(function (i, html) {
            return html.replace(/&nbsp;/g, '');
        });
    });

    updateNonce();
    
    $('body').removeClass('no-js');
});

/**
 * Check if language selector is empty and remove arrow on wpml shortcode approach
 */
if( !$('.wpml-ls-sub-menu li').length ){
    $('.wpml-ls-item-toggle').addClass('wpml-ls-item-toggle--empty');
}

/**
 * Woocommerce breadcrumb on account page to append text base on the page title
 */
if( $('.woocommerce-account.woocommerce-page #breadcrumb').length ){
    $('.woocommerce-account.woocommerce-page #breadcrumb').append($('.woocommerce-account.woocommerce-page h2.title').text());
}

/**
 * Woocommerce registration validation
 */
const accountRequiredCheckboxError = $('.woocommerce-account .woocommerce-error li').text().trim();

if( accountRequiredCheckboxError == 'Błąd: Proszę podać poprawny adres e-mail.'){ // Email is invalid
    $('#customer_login .register-column .woocommerce-form label[for=reg_email]').addClass('label-input-field--error');
}

if( accountRequiredCheckboxError == 'Błąd: Wprowadź hasło do konta.'){ // Password is empty
    $('#customer_login .register-column .woocommerce-form label[for=reg_password]').addClass('label-input-field--error');
}

if( accountRequiredCheckboxError == 'Błąd: Please confirm your password.'){ // Password confirmation does not match
    $('#customer_login .register-column .woocommerce-form label[for=reg_confirm_password]').addClass('label-input-field--error');
}

if( accountRequiredCheckboxError == 'Błąd: Please accept required checkbox.'){ // Checkbox required is not checked
    $('.js-label-checkbox-required').addClass('label-checkbox-required--error');
}

/**
 * Changing default logout link to directly let the user logout instead of redirecting it to account page and let the user confirm the logout
 * Base on Ticket #125, Comment #8
 */
const woocommerceSidebarAccountLinks = $('.header-top .login-link.et_b_header-account .et-mini-content .woocommerce-MyAccount-navigation-link');

woocommerceSidebarAccountLinks.each(function(){
    const linkElem = $(this);
    const linkElemText = $(this).text();
    const linkElemNormalize = linkElemText.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    const linkElemLower = linkElemNormalize.toLowerCase();
    const linkElemTrim = linkElemLower.replace(/\s/g, '');

    if( linkElemTrim == 'wylogujsie' || linkElemTrim == 'logout' ){
        linkElem.children('a').attr('href', '/my-account/?customer-logout=true');
    }
});

function validateEmail(email) {
    var emailRegEx = /\S+@\S+\.\S+/;
    return emailRegEx.test(email);
}

$('.woocommerce-form-register').on('submit', function(e) {
    e.preventDefault();

    var formData = $(this).serialize();

    $.ajax({
        type: 'POST',
        url: customAjax.ajaxurl,
        data: formData + '&action=custom_ajax_register', 
        beforeSend: function() {
            $('.header-top .login-link .woocommerce-form button[type=submit]').addClass('loadingsubmit');
            $('#customer_login .register-column .woocommerce-form button[type=submit]').addClass('loadingsubmit'); //My Account Page
        },
        success: function(response) {
            if(response.success == false){
                const emailInput = $('.woocommerce-form-register input[name=email]');
                const passwordInput = $('.woocommerce-form-register input[name=password]');
                const confirmPasswordInput = $('.woocommerce-form-register input[name=confirm_password]');
                const requiredCheckbox1 = $('.woocommerce-form-register input[name=checkbox-required-1]');
                const requiredCheckbox2 = $('.woocommerce-form-register input[name=checkbox-required-2]');

                $('.header-top .login-link .woocommerce-form button[type=submit]').removeClass('loadingsubmit');
                $('.woocommerce-form-register input').removeClass('input-registration-error');
                $('.register-error-notice').remove();

                // My Account Page
                $('#customer_login .register-column .woocommerce-form button[type=submit]').removeClass('loadingsubmit');

                if( response.data[0].message == 'Please enter your email' || response.data[0].message == 'Podaj adres email' ){
                    emailInput.addClass('input-registration-error');
                }else if( response.data[0].message == 'Please enter a valid email' || response.data[0].message == 'Proszę wpisać poprawny adres email' ){
                    emailInput.addClass('input-registration-error');
                }else if( response.data[0].message == 'Please enter your password' || response.data[0].message == 'Proszę wpisać hasło' ){
                    passwordInput.addClass('input-registration-error');
                }else if( response.data[0].message == 'Please confirm your password' || response.data[0].message == 'Proszę potwierdzić hasło' ){
                    confirmPasswordInput.addClass('input-registration-error');
                }else if( response.data[0].message == 'Password and confirm password does not matched' || response.data[0].message == 'Hasło i potwierdzenie hasła nie są zgodne' ){
                    passwordInput.addClass('input-registration-error');
                    confirmPasswordInput.addClass('input-registration-error');
                }else if( response.data[0].message == 'Please check required checkbox' || response.data[0].message == 'Zaznacz wymagane zgody' ){
                    requiredCheckbox1.addClass('input-registration-error');
                    requiredCheckbox2.addClass('input-registration-error');
                }else{
                    $('.woocommerce-form-register input').removeClass('input-registration-error');
                }

                $('.header-top .login-link .et-mini-content-head').append('<span class="register-error-notice">' + response.data[0].message + '</span>');
                $('<span class="register-error-notice">' + response.data[0].message + '</span>').insertAfter('#customer_login .register-column h2');
                $('.woocommerce-notices-wrapper').addClass('add-notice-margin');
            }else{
                $('.register-error-notice').hide();
                $('.woocommerce-notices-wrapper').removeClass('add-notice-margin');
                window.location.replace("/my-account/");
            }
        }
    });

    return false;
});

/**
 * Fix header main wrapper overlaps sidebar popup when cart, wishlist and compare buttons on header desktop are being triggered
 */
function zIndexHeaderMainWrapperSidebarPopUpTrigger(elementClick, elementClickSidebarContent, elementClickAddClassBodyElement){
    $(elementClick).on('click', function(){
        $('body').addClass(elementClickAddClassBodyElement);
    
        if($('.et-content-shown').length){
            $(document).on('mouseup', function(e) {
                var container = $(elementClickSidebarContent);
    
                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    $('body').removeClass(elementClickAddClassBodyElement);
                }
            });
        }
    });
}

zIndexHeaderMainWrapperSidebarPopUpTrigger('.et_b_header-compare', '.et_b_header-compare .et-mini-content','heading-main-wrapper-index-compare-increase');
zIndexHeaderMainWrapperSidebarPopUpTrigger('.et_b_header-wishlist', '.et_b_header-wishlist .et-mini-content','heading-main-wrapper-index-wishlist-increase');
zIndexHeaderMainWrapperSidebarPopUpTrigger('.et_b_header-cart', '.et_b_header-cart .et-mini-content','heading-main-wrapper-index-cart-increase');

if($('.woocommerce-breadcrumb a').length){
    $('.woocommerce-breadcrumb a').each(function(){
        if($(this).text() == 'Sklep'){
            $(this).addClass('d-none');
            $(this).next('.delimeter').addClass('d-none');
        }
    });
}

$(document).ready(function() {
    const selectAllCheckboxes = $('#reg_checkbox-select-all, #reg_checkbox-select-all_checkout');
    const individualCheckboxes = $('.js-label-checkbox-select input[type="checkbox"]');

    // Check if the select all checkboxes exist
    if (selectAllCheckboxes.length) {
        selectAllCheckboxes.on('change', function() {
            const isChecked = $(this).is(':checked');
            individualCheckboxes.prop('checked', isChecked).attr('checked', isChecked);
        });

        individualCheckboxes.on('change', function() {
            const allChecked = individualCheckboxes.length === individualCheckboxes.filter(':checked').length;
            selectAllCheckboxes.prop('checked', allChecked).attr('checked', allChecked);
        });
    }
});

if($('.header-top .login-link .woocommerce-form #reg_email').length){
    $('.header-top .login-link .woocommerce-form #reg_email').attr('type', 'text');
}

function updateNonce() {
    function updateNonceField(nonceSelector, nonceType) {
        const prevNonce = $(nonceSelector).val();
        if (prevNonce) {
            $.ajax({
                url: customAjax.ajaxurl,
                type: 'POST',
                data: {
                    action: 'generate_new_nonce',
                    security: prevNonce,
                    nonce_type: nonceType
                },
                success: function(response) {
                    if (response.success) {
                        $(nonceSelector).val(response.data.new_nonce);
                        console.log(`Updated ${nonceType} nonce:`, response.data.new_nonce);
                    }
                },
                error: function(error) {
                    console.error(`Error updating ${nonceType} nonce:`, error);
                }
            });
        }
    }

    updateNonceField('#woocommerce-login-nonce', 'login');
    updateNonceField('#woocommerce-register-nonce', 'register');
}
